import { useMutation } from '@apollo/client';
import { FormEvent, useCallback, useReducer, useState } from 'react';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';

import { validateDMCAFormContent } from './utils';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { Column, Container, Row } from 'components/Grid';
import Checkbox from 'components/InputFields/Checkbox/Checkbox';
import TextInput from 'components/InputFields/TextInput/TextInput';
import getConfig from 'config/config';

import Logger from 'lib/utils/Logger';

import { SEND_DMCA_EMAIL_MUTATION } from './DMCAForm.gql';

import {
  SendDmcaEmailMutation,
  SendDmcaEmailMutationVariables,
} from 'types/generated/api';

import styles from './DMCAForm.module.scss';

export type DMCAFormContentReducerStateType = {
  authority: string;
  city: string;
  copyrightOwner: string;
  country: string;
  describe: string;
  email: string;
  fullName: string;
  goodFaith: boolean;
  location: string;
  phone: string;
  section512: boolean;
  signature: string;
  streetAddress: string;
  terminationAccount: boolean;
  underPenalty: boolean;
  url: string;
  zip: string;
};

const BUTTON_DEFAULT = 'Send DMCA Form';
const BUTTON_SUCCESS = 'Success!';
const BUTTON_ERROR = 'Something went wrong.';
const DMCA_EMAIL = 'dmca@verishop.com';

const LEGAL_LABELS = {
  goodFaith:
    '* I have a good faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.',
  section512:
    '* I acknowledge that under Section 512(f) of the DMCA any person who knowingly materially misrepresents that material or activity is infringing may be subject to liability for damages.',
  terminationAccount:
    '* I understand that abuse of this tool will result in termination of my Verishop account.',
  underPenalty:
    '* This notification is accurate, and UNDER PENALITY OF PERJURY, I am authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.',
};

const reCaptchaSiteKey = getConfig('reCaptcha.siteKey');

export const initialState = {
  authority: '',
  city: '',
  copyrightOwner: '',
  country: '',
  describe: '',
  email: '',
  fullName: '',
  goodFaith: false,
  location: '',
  phone: '',
  section512: false,
  signature: '',
  streetAddress: '',
  terminationAccount: false,
  underPenalty: false,
  url: '',
  zip: '',
};

export const NON_REQUIRED_INPUTS = new Set(['url', 'phone']);

const dmcaFormContentReducer = (
  state: DMCAFormContentReducerStateType,
  action: { payload: string | boolean; type: string }
) => {
  const { payload, type } = action;
  return { ...state, [type]: payload };
};

export type DMCAFormProps = {
  onSubmitSuccess?: () => void;
};

const DMCAForm = ({ onSubmitSuccess }: DMCAFormProps) => {
  const [dmcaFormContent, dispatch] = useReducer(
    dmcaFormContentReducer,
    initialState
  );

  const [buttonText, setButtonText] = useState(BUTTON_DEFAULT);
  const [captchaToken, setCaptchaToken] = useState('');

  const handleOnSuccess = useCallback(() => {
    setButtonText(BUTTON_SUCCESS);

    if (typeof onSubmitSuccess === 'function') {
      onSubmitSuccess();
    }
  }, [onSubmitSuccess]);

  const handleOnFormContentChange = useCallback(
    (payload: string | boolean, type: string) => dispatch({ payload, type }),
    [JSON.stringify(dmcaFormContent)]
  );

  const {
    authority,
    city,
    copyrightOwner,
    country,
    describe,
    email,
    fullName,
    goodFaith,
    location,
    phone,
    section512,
    signature,
    streetAddress,
    terminationAccount,
    underPenalty,
    url,
    zip,
  } = dmcaFormContent;

  const [sendDMCAForm, { error, loading }] = useMutation<
    SendDmcaEmailMutation,
    SendDmcaEmailMutationVariables
  >(SEND_DMCA_EMAIL_MUTATION, {
    onCompleted(data) {
      if (data?.sendDMCAEmail?.success) {
        handleOnSuccess();
      } else {
        setButtonText(BUTTON_ERROR);
      }
    },
  });

  const handleFormSubmit = async (event: FormEvent) => {
    try {
      event.preventDefault();
      await sendDMCAForm({
        variables: {
          input: {
            state: dmcaFormContent.location,
            token: captchaToken,
            ...dmcaFormContent,
          },
        },
      });
    } catch (error) {
      Logger.error('Something went wrong submitting form: ', error);
    }
  };

  if (error) {
    Logger.error(JSON.stringify(error));
    setButtonText(BUTTON_ERROR);
  }

  const renderDMCAForm = () => (
    <form className={styles.formRoot} method="post" onSubmit={handleFormSubmit}>
      <Container className={styles.formContainer}>
        <Row>
          <Column xs={12}>
            <TextInput
              autoComplete="url"
              className={styles.textInput}
              label="URL of allegedly infringing content"
              name="url"
              onChange={event =>
                handleOnFormContentChange(event.target.value, 'url')
              }
              placeholder="https://"
              type="url"
              value={url}
            />

            <label>
              <div className={styles.labelText}>
                Description of allegedly infringing content *:
              </div>
              <textarea
                className={styles.textArea}
                name="describe"
                onChange={event =>
                  handleOnFormContentChange(event.target.value, 'describe')
                }
                value={describe}
              />
            </label>
          </Column>
        </Row>
        <Row>
          <Column md={6}>
            <TextInput
              className={styles.textInput}
              label="Copyright Owner Name *"
              name="copyrightOwner"
              onChange={event =>
                handleOnFormContentChange(event.target.value, 'copyrightOwner')
              }
              required
              type="text"
              value={copyrightOwner}
            />

            <TextInput
              className={styles.textInput}
              label="Authority to make this complaint *"
              name="authority"
              onChange={event =>
                handleOnFormContentChange(event.target.value, 'authority')
              }
              required
              type="text"
              value={authority}
            />

            <TextInput
              autoComplete="email"
              className={styles.textInput}
              label="Contact Email *"
              name="email"
              onChange={event =>
                handleOnFormContentChange(event.target.value, 'email')
              }
              placeholder="you@example.com"
              required
              type="email"
              value={email}
            />

            <TextInput
              autoComplete="fullname"
              className={styles.textInput}
              label="Full Name *"
              name="fullname"
              onChange={event =>
                handleOnFormContentChange(event.target.value, 'fullName')
              }
              required
              type="text"
              value={fullName}
            />
          </Column>
          <Column md={6}>
            <TextInput
              autoComplete="address"
              className={styles.textInput}
              label="Street Address *"
              name="address"
              onChange={event =>
                handleOnFormContentChange(event.target.value, 'streetAddress')
              }
              required
              type="text"
              value={streetAddress}
            />

            <Row>
              <Column md={6}>
                <TextInput
                  autoComplete="city"
                  className={styles.textInput}
                  label="City *"
                  name="city"
                  onChange={event =>
                    handleOnFormContentChange(event.target.value, 'city')
                  }
                  required
                  type="text"
                  value={city}
                />
              </Column>
              <Column md={6}>
                <TextInput
                  autoComplete="state"
                  className={styles.textInput}
                  label="State / Province *"
                  name="state"
                  onChange={event =>
                    handleOnFormContentChange(event.target.value, 'location')
                  }
                  required
                  type="text"
                  value={location}
                />
              </Column>
            </Row>

            <Row>
              <Column md={6}>
                <TextInput
                  autoComplete="zip"
                  className={styles.textInput}
                  label="Zip / Postal Code *"
                  name="zip"
                  onChange={event =>
                    handleOnFormContentChange(event.target.value, 'zip')
                  }
                  required
                  type="text"
                  value={zip}
                />
              </Column>
              <Column md={6}>
                <TextInput
                  autoComplete="country"
                  className={styles.textInput}
                  label="Country *"
                  name="country"
                  onChange={event =>
                    handleOnFormContentChange(event.target.value, 'country')
                  }
                  required
                  type="text"
                  value={country}
                />
              </Column>
            </Row>

            <Row>
              <Column md={6}>
                <TextInput
                  autoComplete="phone"
                  className={styles.textInput}
                  label="Phone"
                  name="phone"
                  onChange={event =>
                    handleOnFormContentChange(event.target.value, 'phone')
                  }
                  type="tel"
                  value={phone}
                />
              </Column>
            </Row>
          </Column>
        </Row>
        <div className={styles.divider} />
        <h5 className={styles.subtitle}>
          By checking the following boxes, I state that:
        </h5>
        <div className={styles.divider} />
        <Row>
          <Column md={12}>
            <Checkbox
              className={styles.checkboxes}
              isChecked={goodFaith}
              label={LEGAL_LABELS.goodFaith}
              onChange={() =>
                handleOnFormContentChange(!goodFaith, 'goodFaith')
              }
              required
            />
          </Column>
        </Row>
        <Row>
          <Column md={12}>
            <Checkbox
              className={styles.checkboxes}
              isChecked={underPenalty}
              label={LEGAL_LABELS.underPenalty}
              onChange={() =>
                handleOnFormContentChange(!underPenalty, 'underPenalty')
              }
              required
            />
          </Column>
        </Row>
        <Row>
          <Column md={12}>
            <Checkbox
              className={styles.checkboxes}
              isChecked={section512}
              label={LEGAL_LABELS.section512}
              onChange={() =>
                handleOnFormContentChange(!section512, 'section512')
              }
              required
            />
          </Column>
        </Row>
        <Row>
          <Column md={12}>
            <Checkbox
              className={styles.checkboxes}
              isChecked={terminationAccount}
              label={LEGAL_LABELS.terminationAccount}
              onChange={() =>
                handleOnFormContentChange(
                  !terminationAccount,
                  'terminationAccount'
                )
              }
              required
            />
          </Column>
        </Row>
        <div className={styles.divider} />
        <Row>
          <Column md={6}>
            <TextInput
              className={styles.textInput}
              label="Signature *"
              name="phone"
              onChange={event =>
                handleOnFormContentChange(event.target.value, 'signature')
              }
              required
              type="text"
              value={signature}
            />
          </Column>
        </Row>
        <div className={styles.divider} />
        <small>(*) Required</small>
        <div className={styles.divider} />
        <GoogleReCaptchaProvider reCaptchaKey={reCaptchaSiteKey}>
          <GoogleReCaptcha
            action="send_form"
            onVerify={token => {
              setCaptchaToken(token);
            }}
          />
        </GoogleReCaptchaProvider>
        <small>
          This site is protected by reCAPTCHA and the Google{' '}
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
          <a href="https://policies.google.com/terms">Terms of Service</a>{' '}
          apply.
        </small>
        <Row>
          <Column md={12}>
            <PrimaryButton
              className={styles.sendButton}
              disabled={validateDMCAFormContent(dmcaFormContent)}
              isLoading={loading}
              isSuccess={BUTTON_SUCCESS === buttonText}
            >
              {buttonText}
            </PrimaryButton>
          </Column>
        </Row>
      </Container>
    </form>
  );

  return (
    <div>
      <div className={styles.modalDescription}>
        <p>
          If you believe content on Verishop is infringing your copyright,
          please fill out the form below.
          <br />
          You can also email us at{' '}
          <a href={`mailto:${DMCA_EMAIL}`}>{DMCA_EMAIL}</a> or contact:
        </p>
        <address>
          <strong>Verishop Inc.</strong>
          <br />
          Attn: DMCA Agent
          <br />
          2219 Main St. #171
          <br />
          Santa Monica, CA 90404
        </address>
      </div>
      {renderDMCAForm()}
    </div>
  );
};

export default DMCAForm;
