import classnames from 'classnames';
import { ReactElement, ReactNode } from 'react';

import FooterSocialLink from './FooterSocialLink';
import IDefaultComponentProps from 'components/defaultComponentProps';

import { FooterLinkTypes, IFooterLinkModel } from 'lib/contentful';
import { BaseLink } from 'lib/links';

import styles from './FooterLink.module.scss';

export type FooterLinkProps = IFooterLinkModel & IDefaultComponentProps;

const FooterLink = ({
  className,
  ...restProps
}: FooterLinkProps): ReactElement => (
  <div className={classnames(styles.root, className)}>
    {renderLinkByType(restProps)}
  </div>
);

export const renderLinkByType = ({
  displayText,
  path,
  rel,
  target,
  type,
}: IFooterLinkModel): ReactElement | null => {
  switch (type) {
    case FooterLinkTypes.ABSOLUTE: {
      return (
        <a className={styles.anchor} href={path} rel={rel} target={target}>
          {renderLinkContent({ displayText, path, type })}
        </a>
      );
    }
    case FooterLinkTypes.RELATIVE: {
      return (
        <BaseLink passHref pathName={path}>
          <a
            className={styles.anchor}
            href="placeholder"
            rel={rel}
            target={target}
          >
            {displayText}
          </a>
        </BaseLink>
      );
    }
    case FooterLinkTypes.SOCIAL_FACEBOOK:
    case FooterLinkTypes.SOCIAL_INSTAGRAM:
    case FooterLinkTypes.SOCIAL_PINTEREST:
    case FooterLinkTypes.SOCIAL_SNAPCHAT:
    case FooterLinkTypes.SOCIAL_TWITTER:
    case FooterLinkTypes.SOCIAL_YOUTUBE: {
      return (
        <FooterSocialLink
          displayText={displayText}
          path={path}
          target={target}
          type={type}
        />
      );
    }
    default: {
      // unexpected type - return null;
      return null;
    }
  }
};

export const renderLinkContent = ({
  displayText,
  imageSrc,
}: Partial<IFooterLinkModel>): ReactNode => {
  if (imageSrc) {
    return <img alt={displayText} src={imageSrc} />;
  }
  return displayText;
};

export default FooterLink;
