import classnames from 'classnames';
import { ReactElement } from 'react';
import {
  Col as GridColumn,
  ColProps,
  Container as GridContainer,
  ContainerProps as GridContainerProps,
  Hidden,
  Row as GridRow,
  RowProps,
  Visible,
} from 'react-grid-system';

import BreakpointDebugger from './BreakpointDebugger';

import { isGridDebugMode } from 'lib/utils/debug';
import './setup';

import styles from './Grid.module.scss';

type ContainerProps = GridContainerProps & {
  debug?: boolean;
};

const Column = (props: ColProps): ReactElement => {
  const { className, ...rest } = props;
  const shouldEnableVisualDebugging = props.debug || isGridDebugMode();
  const classes = classnames(className, {
    [styles.columnDebug]: shouldEnableVisualDebugging,
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: just overriding a few styles
  return <GridColumn className={classes} data-grid-type="column" {...rest} />;
};

const Row = (props: RowProps): ReactElement => {
  const { className, ...rest } = props;

  const classes = classnames(className, {
    [styles.rowDebug]: !!props.debug,
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: just overriding a few styles
  return <GridRow className={classes} data-grid-type="row" {...rest} />;
};

const Container = (props: ContainerProps): ReactElement => {
  const { className, ...rest } = props;
  const shouldEnableVisualDebugging = props.debug || isGridDebugMode();

  const classes = classnames(className, styles.container, {
    [styles.containerDebug]: shouldEnableVisualDebugging,
  });
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: just overriding a few styles
    <GridContainer className={classes} data-grid-type="container" {...rest} />
  );
};

export { BreakpointDebugger, Column, Container, Hidden, Row, Visible };
