import omit from 'lodash/omit';

import {
  DMCAFormContentReducerStateType,
  NON_REQUIRED_INPUTS,
} from './DMCAForm';

export const validateDMCAFormContent = (
  dmcaFormContent: DMCAFormContentReducerStateType
) => {
  const requiredInputs = omit({ ...dmcaFormContent }, ...NON_REQUIRED_INPUTS);
  return !!Object.entries(requiredInputs).filter(
    ([, value]) => value === false || value === ''
  ).length;
};
