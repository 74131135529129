import { useCallback, useState } from 'react';

import DMCAForm from 'components/DMCA/DMCAForm';
import Modal from 'components/Modals/Modal/Modal';

import styles from './DMCAModal.module.scss';

const CLOSE_MODAL_DELAY = 1200;

const DMCAModal = () => {
  const [isDMCAModalVisible, setIsDMCAModalVisible] = useState(false);

  const handleOnSuccess = useCallback(() => {
    setTimeout(() => handleOnClose(), CLOSE_MODAL_DELAY);
  }, []);

  const handleOnClose = useCallback(() => {
    setIsDMCAModalVisible(false);
  }, [isDMCAModalVisible]);

  return (
    <>
      <button
        className={styles.button}
        onClick={() => setIsDMCAModalVisible(true)}
      >
        DMCA
      </button>
      <Modal
        className={styles.root}
        header="DMCA"
        isOpen={isDMCAModalVisible}
        onCloseClick={handleOnClose}
      >
        <DMCAForm onSubmitSuccess={handleOnSuccess} />
      </Modal>
    </>
  );
};

export default DMCAModal;
