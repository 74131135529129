import classnames from 'classnames';

import FooterLink, { FooterLinkProps } from './FooterLink';
import IDefaultComponentProps from 'components/defaultComponentProps';

import styles from './FooterSection.module.scss';

export interface IFooterSectionProps extends IDefaultComponentProps {
  children?: unknown;
  items: FooterLinkProps[];
  title: string;
}

const FooterSection = ({
  children,
  className,
  items,
  title,
}: IFooterSectionProps) => (
  <div className={classnames(styles.root, className)}>
    <div className={styles.title}>{title}</div>
    <div className={styles.content}>
      {items &&
        items.map((item: FooterLinkProps) => (
          <FooterLink
            className={styles.link}
            key={item.displayText}
            {...item}
          />
        ))}
      {children}
    </div>
  </div>
);

export default FooterSection;
