import { createContext, useContext } from 'react';

import { SiteFurniture } from 'types/app';

export type SiteFurnitureContextValue =
  | { available: boolean }
  | ({ available: true } & SiteFurniture);

const SiteFurnitureContext = createContext<SiteFurnitureContextValue>({
  available: false,
});

SiteFurnitureContext.displayName = 'SiteFurnitureContext';

export const useSiteFurnitureContext = (): SiteFurnitureContextValue => {
  return useContext(SiteFurnitureContext);
};

export default SiteFurnitureContext;
