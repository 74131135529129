import classnames from 'classnames';
import { ChangeEvent, ReactNode } from 'react';

import DefaultComponentProps from 'components/defaultComponentProps';

import CheckMarkIcon from 'assets/icons/ic-checkmark.inline.svg';

import styles from './Checkbox.module.scss';

type CheckboxProps = {
  isChecked: boolean;
  isDisabled?: boolean;
  isSkippedFromTabNavigation?: boolean;
  label?: ReactNode;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
} & DefaultComponentProps;

const Checkbox = ({
  className,
  isChecked,
  isDisabled,
  isSkippedFromTabNavigation,
  label,
  onChange,
  required,
}: CheckboxProps) => (
  <div
    className={classnames(
      styles.container,
      {
        [styles.isActive]: isChecked,
      },
      className
    )}
  >
    <label>
      <input
        checked={isChecked}
        className={styles.hiddenCheckbox}
        disabled={isDisabled}
        onChange={onChange}
        required={required}
        tabIndex={isSkippedFromTabNavigation ? -1 : 0}
        type="checkbox"
      />
      <div
        className={classnames(styles.checkbox, {
          [styles.isDisabled]: isDisabled,
        })}
      >
        <CheckMarkIcon className={styles.icon} />
      </div>
      <span className={styles.label}>{label}</span>
    </label>
  </div>
);

export default Checkbox;
