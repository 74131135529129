export const GRID_DEBUG_MODE_KEY = 'isGridDebugMode';

export const isGridDebugMode = (): boolean => {
  try {
    // TODO: [CH9756] - replace with VerishopStorage
    return !!localStorage.getItem(GRID_DEBUG_MODE_KEY)?.match(/[true]/i);
  } catch (error) {
    return false;
  }
};
