import { ReactElement } from 'react';

import IDefaultComponentProps from 'components/defaultComponentProps';

import { FooterLinkTypes, IFooterLinkModel } from 'lib/contentful';

import FacebookIcon from 'assets/icons/ic-soc-facebook.inline.svg';
import InstagramIcon from 'assets/icons/ic-soc-instagram.inline.svg';
import PinterestIcon from 'assets/icons/ic-soc-pinterest.inline.svg';
import SnapchatIcon from 'assets/icons/ic-soc-snapchat.inline.svg';
import TwitterIcon from 'assets/icons/ic-soc-twitter.inline.svg';
import YouTubeIcon from 'assets/icons/ic-soc-youtube.inline.svg';

import styles from './FooterSocialLink.module.scss';

export type FooterSocialLinkProps = IFooterLinkModel & IDefaultComponentProps;

const FooterSocialLink = ({
  displayText,
  path,
  target,
  type,
}: FooterSocialLinkProps) => {
  let icon: ReactElement;

  switch (type) {
    case FooterLinkTypes.SOCIAL_FACEBOOK: {
      icon = <FacebookIcon className={styles.icon} />;
      break;
    }
    case FooterLinkTypes.SOCIAL_INSTAGRAM: {
      icon = <InstagramIcon className={styles.icon} />;
      break;
    }
    case FooterLinkTypes.SOCIAL_PINTEREST: {
      icon = <PinterestIcon className={styles.icon} />;
      break;
    }
    case FooterLinkTypes.SOCIAL_SNAPCHAT: {
      icon = <SnapchatIcon className={styles.icon} />;
      break;
    }
    case FooterLinkTypes.SOCIAL_TWITTER: {
      icon = <TwitterIcon className={styles.icon} />;
      break;
    }
    case FooterLinkTypes.SOCIAL_YOUTUBE: {
      icon = <YouTubeIcon className={styles.icon} />;
      break;
    }
    default: {
      // unexpected type - return null;
      return null;
    }
  }

  return (
    <a
      aria-label={'Connect with ' + displayText}
      className={styles.socialLink}
      href={path}
      rel="nofollow"
      target={target}
    >
      <span className={styles.tooltiptext}>
        {'Connect with ' + displayText}
      </span>
      {icon}
    </a>
  );
};

export default FooterSocialLink;
