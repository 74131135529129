import { gql } from '@apollo/client';

export const SEND_DMCA_EMAIL_MUTATION = gql`
  mutation sendDMCAEmail($input: EmailDMCAInput!) {
    sendDMCAEmail(input: $input) {
      success
      validationErrors {
        field
        message
      }
    }
  }
`;
